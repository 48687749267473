import React from 'react'
import './AddModal.css'

class AddModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  add (type) {
    let layout = this.props.layout

    let newElement = {
      'h': 1,
      'i': layout.lg.length.toString(),
      'fetch_url': '',
      'static': false,
      'moved': false,
      'chartType': 'barChart',
      'x': 0,
      'y': 0,
      'w': 1,
      'type': '',
      'fetchIntervalInSeconds': 60,
      'bgColor': 'default',
      'title': 'Titel'
    }

    if (type === 'Sonne') {
      newElement.type = 'sonne'
      newElement.title = 'Sonnentage'
      newElement.key = 'sonnentage'
      newElement.dataKey = 'monat'
      newElement.href = '/#sonne'
      newElement.fetch_url = 'https://htldashboard.micecim.at/sonnes'
      layout.lg.push(newElement)
    } else if (type === 'Temperature') {
      newElement.type = 'temperature'
      newElement.title = 'Temperaturen'
      newElement.key = 'GradCelsius'
      newElement.dataKey = 'stunde'
      newElement.href = '/#temperature'
      newElement.fetch_url = 'https://htldashboard.micecim.at/temperaturs'
      layout.lg.push(newElement)
    }

    fetch('https://htldashboard.micecim.at/layouts/1', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        layout: layout
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.props.update()
      })
  }

  render () {
    if (this.props.visible) {
      return (
        <div className="addModal">
          <button className="btn btn-success m-5" onClick={() => this.add('Sonne')}>Sonne</button>
          <button className="btn btn-success m-5" onClick={() => this.add('Temperature')}>Temperature</button>
        </div>
      )
    }
    return (
      <div className="position-absolute">
      </div>
    )
  }
}

export default AddModal
