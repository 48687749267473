import React from 'react'
import './ChangeModal.css'

class ChangeModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      bgColor: this.props.data.bgColor,
      chartType: this.props.data.chartType
    }
    this.handleBgColor = this.handleBgColor.bind(this)
    this.handleChartType = this.handleChartType.bind(this)
  }

  handleBgColor () {
    let box = document.getElementById('selectBgColor')
    this.setState({ bgColor: box.options[box.selectedIndex].value })
  }
  handleChartType () {
    let box = document.getElementById('selectChartType')
    this.setState({ chartType: box.options[box.selectedIndex].value })
  }

  change () {
    let data = this.props.allData
    data.lg[this.props.id].bgColor = this.state.bgColor
    data.lg[this.props.id].chartType = this.state.chartType

    fetch('https://htldashboard.micecim.at/layouts/1', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        layout: data
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.props.update()
        this.props.hide()
      })
  }

  render () {
    if (this.props.visible) {
      return (
        <div className="changeModal text-center">
          <div className="row m-3">
            <p className="text-white col-4">Background-Color</p>
            <select value={this.state.bgColor} id="selectBgColor" className="form-control w-100 col-8"
              onChange={this.handleBgColor}>
              <option index={0} value="default">Default</option>
              <option index={1} value="grey">Grey</option>
              <option index={2} value="white">White</option>
            </select>
          </div>
          <div className="row m-3">
            <p className="text-white col-4">Chart-Type</p>
            <select id="selectChartType" className="form-control w-100 col-8"
              onChange={this.handleChartType}>
              <option index={0} value="barChart">BarChart</option>
              <option index={1} value="lineChart">LineChart</option>
            </select>
          </div>
          <button className="text-center btn btn-success" onClick={() => this.change()}>Save</button>
        </div>
      )
    }
    return (
      <div>
      </div>
    )
  }
}

export default ChangeModal
