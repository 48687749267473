import React from 'react'

import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer } from 'recharts'

const SuperBarChart = (props) => {
  const data = props.data

  return (
    <ResponsiveContainer width="100%" height="80%">
      <BarChart width={'100%'} height={250} data={props.content}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={props.dataKey} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar fill="#8884d8" dataKey={props.dataKeyBar} />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default SuperBarChart
