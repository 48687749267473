import React, { useState, useEffect } from 'react'
import './Widget.css'
import DeleteButton from '../DeleteButton/DeleteButton'
import ChangeButton from '../ChangeButton/ChangeButton'
import BarChart from '../BarChart'
import LineChart from '../LineChart'

const Widget = (props) => {
  let settings = () => {
    if (props.editMode) {
      return (
        <div className="p-3 changeBar">
          <DeleteButton className="" update={props.getData} allData={props.allData} id={props.id} data={props.data}></DeleteButton>
          <ChangeButton className="" update={props.getData} allData={props.allData} id={props.id} data={props.data} bgColor={props.data.bgColor}></ChangeButton>
        </div>
      )
    }
  }

  let [data, setData] = useState(props.data)
  let [content, setContent] = useState(undefined)

  useEffect(() => {
    const abortcontroller = new AbortController()
    const signal = abortcontroller.signal
    setData(props.data)

    fetch(data.fetch_url, {
      method: 'GET',
      signal: signal
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setContent(responseJson)
      })
    return function cleanup () {
      abortcontroller.abort()
    }
  }, [props.data, data.fetch_url])

  let chart = () => {
    if (data.chartType === 'barChart') {
      return (
        <BarChart dataKeyBar={data.key} dataKey={data.dataKey} content={content} data={data}></BarChart>
      )
    } else if (data.chartType === 'lineChart') {
      return (
        <LineChart dataKeyBar={data.key} dataKey={data.dataKey} content={content} data={data}></LineChart>
      )
    }
  }

  let Widget = () => {
    return (
      <div className={data.bgColor + ' m-1 p-3  Widget'}>
        <p>{props.data.title}</p>
        { props.children }
        { chart() }
        { settings() }
      </div>
    )
  }

  if (!props.editMode) {
    return (
      <a href={data.href}>
        { Widget() }
      </a>
    )
  } else {
    return (
      <a>
        { Widget() }
      </a>
    )
  }
}

export default Widget
