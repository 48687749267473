import React from 'react'
import './DeleteButton.css'

const DeleteButton = props => {
  function remove (id) {
    let data = props.allData
    data.lg.splice(id, 1)

    fetch('https://htldashboard.micecim.at/layouts/1', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        layout: data
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        props.update()
      })
  }

  return (
    <div className="delete" onClick={() => remove(props.id)}></div>
  )
}

export default DeleteButton
