import 'bootstrap/dist/css/bootstrap.css'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import React, { Component } from 'react'
import './style.css'
import AddModal from './components/AddModal/AddModal'
import { WidthProvider, Responsive } from 'react-grid-layout'
import Widget from './components/Widget/Widget'
import { wrap } from './functions/Wrapper'
import AddBlade from './components/Blade/AddBlade'

const ResponsiveGridLayout = WidthProvider(Responsive)

class App extends Component {
  constructor (props) {
    super(props)

    this.state = {
      layouts: {
        lg: [{}],
        md: [{}],
        sm: [{}]
      },
      editMode: false,
      addElementVisible: false
    }

    this.getLayout()

    this.getLayout = this.getLayout.bind(this)
    this.setEditMode = this.setEditMode.bind(this)
    this.cancelLayout = this.cancelLayout.bind(this)
    this.saveLayout = this.saveLayout.bind(this)
  }

  getLayout () {
    fetch('https://htldashboard.micecim.at/layouts?user_id=1', {
      method: 'GET'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          layouts: responseJson[0].layout
        })
        return responseJson[0].layout
      })
  }

  addElement () {
    this.setState({
      addElementVisible: true
    })
  }

  setEditMode (value) {
    this.setState({
      editMode: value
    })
  }

  componentDidMount () {
  }

  onLayoutChange (layout, layouts) {
    let currentLayout = this.state.layouts

    if (this.state.editMode) {
      let newLayout = wrap(currentLayout, layouts, layout)
      this.setState({ layouts: newLayout })
    }
  }

  onBreakpointChange (layout, layouts) {
    this.getLayout()
  }

  saveLayout () {
    this.setEditMode(false)

    fetch('https://htldashboard.micecim.at/layouts/1', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        layout: this.state.layouts
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          layouts: responseJson.layout,
          addElementVisible: false
        })
      })
  }

  cancelLayout () {
    this.getLayout()
    this.setState({
      addElementVisible: false
    })
    this.setEditMode(false)
  }

  renderComponents () {
    let components = []
    const data = this.state.layouts.lg

    for (var i = 0; i < data.length; i++) {
      components.push(
        <div key={i}>
          <Widget key={i} id={i} editMode={this.state.editMode} getData={this.getLayout} data={data[i]} allData={this.state.layouts}>
          </Widget>
        </div>
      )
    }
    return components
  }

  renderBlade () {
    if (this.state.editMode) {
      return (
        <AddBlade save={this.saveLayout} cancel={this.cancelLayout} edit={this.setEditMode} layout={this.state.layouts} update={this.getLayout}></AddBlade>
      )
    }
  }

  render () {
    return (
      <div className="dashboard">
        {this.renderBlade()}
        <div className="float-right">
          {this.renderSettings()}
        </div>
        <h2 className="m-3">Datenpunkte:</h2>
        <div>
          <ResponsiveGridLayout
            isDraggable={this.state.editMode}
            isResizable={this.state.editMode}
            breakpoints={{ lg: 1200, md: 768, sm: 480, xs: 0, xxs: 0 }}
            className="layout"
            cols={{ lg: 6, md: 3, sm: 2, xs: 1, xxs: 1 }}
            rowHeight={240} // 16em
            useCSSTransforms={true}
            margin={[16, 16]} // 1em
            layouts={this.state.layouts}
            onLayoutChange={(layout, layouts) =>
              this.onLayoutChange(layout, layouts)
            }
            onBreakpointChange={(layout, layouts) =>
              this.onBreakpointChange(layout, layouts)
            }>
            {this.renderComponents()}
          </ResponsiveGridLayout>
        </div>
        <AddModal visible={this.state.addElementVisible} update={this.getLayout} layout={this.state.layouts}></AddModal>
      </div>
    )
  }

  renderSettings () {
    if (!this.state.editMode) {
      return (
        <div className="m-3">
          <button className="mr-2 btn btn-secondary" onClick={() => this.setEditMode(true)}>Edit</button>
        </div>
      )
    } else {
      return (
        <div className="m-3">
          <button className="mr-2 btn btn-success" onClick={() => this.addElement()}>Add</button>
          <button className="mr-2 btn btn-success" onClick={() => this.saveLayout()}>Save</button>
          <button className="mr-2 btn btn-secondary" onClick={() => this.cancelLayout()}>Cancel</button>
        </div>
      )
    }
  }
}

export default App
