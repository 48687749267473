export const wrap = (currentLayout, layouts, layout) => {
  // just changing the params needed to change
  for (var z = 0; z < layouts.lg.length && z < currentLayout.lg.length; z++) {
    currentLayout.lg[z].i = layouts.lg[z].i
    currentLayout.lg[z].h = layouts.lg[z].h
    currentLayout.lg[z].w = layouts.lg[z].w
    currentLayout.lg[z].x = layouts.lg[z].x
    currentLayout.lg[z].y = layouts.lg[z].y
  }

  currentLayout.md = []
  currentLayout.sm = []

  for (var i = 0; i < currentLayout.lg.length; i++) {
    currentLayout.md[i] = (({ x, y, i, w, h }) => ({ x, y, i, w, h }))(currentLayout.lg[i])
    currentLayout.sm[i] = (({ x, y, i, w, h }) => ({ x, y, i, w, h }))(currentLayout.lg[i])
    switch (currentLayout.lg[i].w) {
      case 6:
        currentLayout.md[i].w = 3
        break
      case 5:
        currentLayout.md[i].w = 3
        break
      case 4:
        currentLayout.md[i].w = 3
        break
      case 3:
        currentLayout.md[i].w = 3
        break
      case 2:
        currentLayout.md[i].w = 2
        break
      case 1:
        currentLayout.md[i].w = 1
        break
      default:
        break
    }
    if (currentLayout.lg[i].x > 3) {
      currentLayout.md[i].y++
    }
  }

  for (var r = 0; r < currentLayout.lg.length; r++) {
    switch (currentLayout.lg[r].h) {
      case (currentLayout.lg[r].h > 6):
        currentLayout.md[r].h = 3
        currentLayout.sm[r].h = 2
        break
      case 6:
        currentLayout.md[r].h = 3
        currentLayout.sm[r].h = 2
        break
      case 5:
        currentLayout.md[r].h = 3
        currentLayout.sm[r].h = 2
        break
      case 4:
        currentLayout.md[r].h = 3
        currentLayout.sm[r].h = 2
        break
      case 3:
        currentLayout.md[r].h = 2
        currentLayout.sm[r].h = 2
        break
      case 2:
        currentLayout.md[r].h = 2
        currentLayout.sm[r].h = 2
        break
      case 1:
        currentLayout.md[r].h = 1
        currentLayout.sm[r].h = 1
        break
      default:
        break
    }
  }

  // now change x and y in md / sm if changed in this breakpoint
  if (layouts.md) {
    for (var x = 0; x < layouts.md.length && x < currentLayout.md.length; x++) {
      currentLayout.md[x].x = layouts.md[x].x
      currentLayout.md[x].y = layouts.md[x].y
    }
    for (var p = 0; p < layouts.sm.length && p < currentLayout.sm.length; p++) {
      currentLayout.sm[p].x = layouts.sm[p].x
      currentLayout.sm[p].y = layouts.sm[p].y
    }
  }

  console.log(currentLayout)
  return (currentLayout)
}
