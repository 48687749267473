import React, { useState } from 'react'
import ChangeModal from '../ChangeModal/ChangeModal'
import './ChangeButton.css'

const ChangeButton = props => {
  let [visible, setVisible] = useState(false)

  return (
    <div>
      <div className="settingsButton mt-2" onClick={() => setVisible(true)}></div>
      <ChangeModal visible={visible} update={props.update} hide={() => setVisible(false)} id={props.id} allData={props.allData} data={props.data}></ChangeModal>
    </div>
  )
}

export default ChangeButton
