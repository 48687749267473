import React from 'react'
import './AddBlade.css'
import Draggable from 'react-draggable'

class Blade extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      class: '',
      x: 0,
      y: 0
    }
    console.log(props.layout)
    this.close = this.close.bind(this)
    this.onDrag = this.onDrag.bind(this)
    this.cancel = this.cancel.bind(this)
  }

  componentDidMount () {
    this.setState({ class: 'active' })
  }

  close () {
    this.setState({ class: '' })
    this.props.edit(false)
    this.props.save()
  }

  onDrag (event, type) {
    if (event.x < window.innerWidth - 250) {
      let layout = this.props.layout
      let newElement = {
        'h': 1,
        'i': layout.lg.length.toString(),
        'fetch_url': '',
        'static': false,
        'moved': false,
        'chartType': 'barChart',
        'x': 0,
        'y': 0,
        'w': 3,
        'type': '',
        'fetchIntervalInSeconds': 60,
        'bgColor': 'default',
        'title': 'Titel'
      }

      if (type === 'sun') {
        newElement.type = 'sonne'
        newElement.title = 'Sonnentage'
        newElement.key = 'sonnentage'
        newElement.dataKey = 'monat'
        newElement.href = '/#sonne'
        newElement.fetch_url = 'https://htldashboard.micecim.at/sonnes'
        layout.lg.push(newElement)
      } else if (type === 'temperature') {
        newElement.type = 'temperature'
        newElement.title = 'Temperaturen'
        newElement.key = 'GradCelsius'
        newElement.dataKey = 'stunde'
        newElement.href = '/#temperature'
        newElement.fetch_url = 'https://htldashboard.micecim.at/temperaturs'
        layout.lg.push(newElement)
      }

      fetch('https://htldashboard.micecim.at/layouts/1', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          layout: layout
        })
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.props.update()
        })
    } else {
      console.log('inside')
    }
  }

  cancel () {
    this.props.cancel()
  }

  render () {
    return (
      <div id="mySidenav" className={'sidenav ' + this.state.class}>
        <button className='closebtn' onClick={this.close}></button>
        <div className='widget-catalog-item'>
          <Draggable
            defaultPosition={{ x: 0, y: 0 }}
            position={{ x: this.state.x, y: this.state.y }}
            onStop={(event) => this.onDrag(event, 'sun')}
          >
            <div className={'widget-draggable'}>
              <div>
                sun
              </div>
            </div>
          </Draggable>
        </div>
        <div className='widget-catalog-item'>
          <Draggable
            defaultPosition={{ x: 0, y: 0 }}
            position={{ x: this.state.x, y: this.state.y }}
            onStop={(event) => this.onDrag(event, 'temperature')}
          >
            <div className={'widget-draggable'}>
              <div>
               temperature
              </div>
            </div>
          </Draggable>
        </div>
        <button className='cancel' onClick={this.cancel}>Cancel</button>
      </div>
    )
  }
}

export default Blade
