import React from 'react'

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts'

const SuperLineChart = (props) => {
  const data = props.data

  return (
    <ResponsiveContainer width="100%" height="80%">
      <LineChart width={'100%'} height={250} data={props.content}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={props.dataKey} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey={props.dataKeyBar} stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default SuperLineChart
